import React from 'react'

export const ContactComponent = () => {
  return (
    <div className="contact-whatsapp">
      <h3>Customer Service</h3>
      <a href="https://wa.me/50258507070">
        <img src="../assets/wa.png" alt="WhatsApp" />
      </a>
    </div>
  )
}
