import { FooterComponent } from "../components/FooterComponent"
import '../styles/Poll.css'


export const PollPage = () => {
  return (
    <>
        <div className="poll">
            <h1>¡Gracias por viajar con nosotros!</h1>
            <hr />
            <p>Agradecemos su tiempo para visitar nuestro hermoso pais. Para asegurarnos que haya tenido una buena experiencia y sabber como podemos mejor le agradecemos contestar las siguientes preguntas.</p>
            <form>
                <div className="poll-question">
                    <h2>¿Qué fue lo mejor de su viaje?</h2>
                    <select name="best-experience" id="best-experience">
                        <option value="1">La comida</option>
                        <option value="2">La cultura</option>
                        <option value="3">La naturaleza</option>
                        <option value="4">Otro</option>
                    </select>
                    <h2> b</h2>
                </div>
            </form>
        </div>
        <FooterComponent />
    </>
  )
}
