import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


export const MenuComponent = () => {
  const location = useLocation();
  const [active, setActive] = useState(['active', '', '', '', '',]);
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;

    switch (path) {
        case '/':
            setActive(['active', '', '', '', '',])
            break;
        case '/tours-from-guatemala-city':
            setActive(['', 'active', '', '', '',])
            break;
        case '/tours-from-antigua-guatemala':
            setActive(['', 'active', '', '', '',])
            break;
        case '/tours-from-flores':
            setActive(['', 'active', '', '', '',])
            break;
        case '/circuits':
            setActive(['', '', 'active', '', '',])
            break;
        case '/shuttles':
            setActive(['', '', '', 'active', '',])
            break;
        case '/private-transportation':
            setActive(['', '', '', 'active', '',])
            break;
        case '/contact':
            setActive(['', '', '', '', 'active',])
            break;
        default:
            break;
    }

    if (window.innerWidth < 1170) {
        const toggle = document.getElementsByClassName('navbar-toggler')[0];
        const divDropdowned = document.getElementById('navbarSupportedContent');
        toggle.className = 'navbar-toggler collapsed';
        toggle.ariaExpanded = false;
        divDropdowned.className = 'navbar-collapse toggle-navbar collapse';
    }
  }, [location.pathname])
  

  const handleRoute = ( e, dir ) => {
    e.preventDefault();
    navigate( dir );
  };


  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark navbar-custom">
        <a className="navbar-brand nav-logo" href="../" onClick={ e => handleRoute(e, '../')}><img src='../assets/logo.png' alt='logo-turismo-guate' /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
            <span className="navbar-toggler-icon"></span>
        </button>
        {/* <p>{ window.screen.width }</p> */}

        <div className="collapse navbar-collapse toggle-navbar" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
            <li className={`nav-item ${active[0]}`}>
                <a className="nav-link" href="../" onClick={ e => handleRoute(e, '../')}>Home <span className="sr-only">(current)</span></a>
            </li>
            <li className={`nav-item ${active[1]} dropdown`}>
                <a className="nav-link dropdown-toggle" href="../" role="button" data-toggle="dropdown" aria-expanded="false">
                Tours saliendo desde...
                </a>
                <div className="dropdown-menu" style={{backgroundColor: '#336699', border: 'none', color: '#FFF', textAlign: 'right'}}>
                <a className="dropdown-item" href="../tours-from-guatemala-city" onClick={ e => handleRoute(e, '../tours-from-guatemala-city')} style={{ color: '#FFF'}}>Guatemala City</a>
                <a className="dropdown-item" href="../tours-from-antigua-guatemala" onClick={ e => handleRoute(e, '../tours-from-antigua-guatemala')} style={{ color: '#FFF'}}>Antigua Guatemala</a>
                <a className="dropdown-item" href="../tours-from-flores" onClick={ e => handleRoute(e, '../tours-from-flores')} style={{ color: '#FFF'}}>Tikal/Flores</a>
                <div className="dropdown-divider"></div>
                </div>
            </li>
            <li className={`nav-item ${active[2]}`}>
                <a className="nav-link" href="../circuits" onClick={ e => handleRoute(e, '../circuits')}>Circuitos Turísticos</a>
            </li>
            <li className={`nav-item ${active[3]} dropdown`}>
                <a className="nav-link dropdown-toggle" href="../" role="button" data-toggle="dropdown" aria-expanded="false">
                Transporte
                </a>
                <div className="dropdown-menu navbar-dropdown" style={{backgroundColor: '#336699', border: 'none', textAlign: 'right'}}>
                <a className="dropdown-item" href="../tours-from-guatemala-city" onClick={ e => handleRoute(e, '../shuttles')} style={{ color: '#FFF'}}>Shuttle Bus</a>
                <a className="dropdown-item" href="../tours-from-antigua-guatemala" onClick={ e => handleRoute(e, '../private-transportation')} style={{ color: '#FFF'}}>Transporte Privado</a>
                <div className="dropdown-divider"></div>
                </div>
            </li>
            <li className={`nav-item ${active[4]}`}>
                <a className="nav-link" href="../contact-us" onClick={ e => handleRoute(e, '../contact-us')}>Contáctanos</a>
            </li>
            </ul>
        </div>
    </nav>
  )
}
