import React from 'react'
// import { CalificationSection } from './CalificationSection'
import { useNavigate } from 'react-router-dom';

export const TourOverview = ({ cardImg, cardImgAlt, cardTitle, cardLink, cardPrice, cardCalification, cardAmountComments }) => {
  const navigate = useNavigate();

  const handleClick = (e, link) => {
    e.preventDefault();
    navigate( link );
  };
  return (
    <>
        <div className="tour-container-overview">
            <a href={ cardLink } className="tour-container-overview-link" onClick={ (event) => handleClick(event, cardLink) }>
                <img src={ `../assets/${cardImg}` } alt={ cardImgAlt } className="tour-prev-img" />
                {/* <div className="tour-overview-info">
                    <div className="row">
                        <div className="col-7">
                            <CalificationSection stars={cardCalification} amountReviews={cardAmountComments} />
                        </div>
                        <div className="price-col">
                            <img src={cardPrice} className='icono-precio' alt="Precio" />
                        </div>
                    </div>
                </div> */}
            </a>
        </div>
    </>
  )
}
