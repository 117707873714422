const createTour = ( id, title, stars, amountReviews, photos, priceImg, price, /* minimoPersonas,*/ preciosPorPersona, time, description, schedules, highlights, included, notIncluded, whatToExpect, additionalInformation, cancellationPolicy, FAQ, travelerPhotos, reviews ) =>  {
	return {
		id, // Alphanumeric - id
		title, // String - title
		stars, // Double - stars
		amountReviews, // Integer - amountReviews 
		photos, // Array - photos
        priceImg, // String - priceImg
		price, // Double - price
        // minimoPersonas, // Integer - minimoPersonas
        preciosPorPersona, // Double - preciosPorPersona
		time, // Integer - time
		description, // String - description
        schedules, // Array - schedules
        highlights, // Array - highlights
		included, // Array - included
		notIncluded, // Array - notIncluded
		whatToExpect, // String - whatToExpect
		additionalInformation, // String - additionalInformation
		cancellationPolicy, // String - cancellationPolicy
		FAQ, // Array -> Object -> String - FAQ
		travelerPhotos, // Array - travelerPhotos
		reviews, // Array -> Object - reviews
	};
};

// const paraLoremIpsum = `Cupidatat Lorem quis eu fugiat consectetur cupidatat ut sit labore velit mollit Lorem. Excepteur eiusmod sunt occaecat cupidatat exercitation non cillum magna irure. Sint exercitation mollit adipisicing veniam aliqua velit aute esse velit incididunt duis adipisicing fugiat. Ad cillum incididunt fugiat consectetur et incididunt exercitation incididunt. Incididunt veniam tempor et non aliquip pariatur et pariatur duis irure. Et eiusmod ea cupidatat aute.
// Esse et enim in id aute occaecat tempor labore. Labore elit sunt id in. Sunt officia ea qui aliqua ad commodo ipsum. Minim sunt amet ullamco cupidatat elit ad qui incididunt sunt eu dolore. Qui officia duis proident veniam ipsum sint.
// Ad cupidatat ullamco aute aliqua do officia sunt Lorem. Proident quis mollit consectetur est Lorem. Cupidatat officia adipisicing laboris ea.`;
// const departureRegular = 'El transporte sale de la zona 10 de Ciudad de Guatemala, si se encuentra hospedado en esta zona se le puede recoger en su hotel';
const cancellation_policy = "Estamos orientados a servirle y hacerlo bien, por lo que en el caso de este tour, programaremos el tour para usted y si por causas de fuerza mayor tiene que cancelar, agradecemos hacerlo saber 24 horas antes.";
const departureRegular = {
    place: 'Holiday Inn Guatemala',
    direction: '1 Avenida 13-22, zona 10, Ciudad de Guatemala, 01010',
    urlLocation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7866.723884980584!2d-90.52438945494913!3d14.599842288400783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a222ca2589c3%3A0x670b9e96c2003ff4!2sHoliday%20Inn%20Guatemala!5e0!3m2!1ses!2sgt!4v1689195877453!5m2!1ses!2sgt',
};
const departureAntigua = {
    place: 'Café Barista',
    direction: '5a Avenida Norte 4, Antigua Guatemala',
    urlLocation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d907.0129122808847!2d-90.73388527987302!3d14.556815614738472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85890e734b9e62d1%3A0x9395fecda3386c26!2sCaf%C3%A9%20Barista!5e0!3m2!1ses!2sgt!4v1689201147263!5m2!1ses!2sgt',
};
const departureFlores = {
    place: 'Yo Amo Petén',
    direction: 'Isla de Flores',
    urlLocation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d238.55853788988267!2d-89.89126327608905!3d16.928419000283117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5f8cd9170ec9c3%3A0xd10930fef4887e86!2sYO%20AMO%20PETEN!5e0!3m2!1ses!2sgt!4v1689205731286!5m2!1ses!2sgt',
};
const departureAirportGua = {
    place: 'Aeropuerto Internacional "La Aurora"',
    direction: '9 Avenida 14-75, zona 13, Ciudad de Guatemala, 00013',
    urlLocation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.194756422414!2d-90.53326562466715!3d14.58797557738519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a15f18a23e65%3A0xa8d9f9b1ae2d4eca!2sAeropuerto%20Internacional%20La%20Aurora!5e0!3m2!1ses!2sgt!4v1689269764953!5m2!1ses!2sgt',
};
const departureTransmetro = {
    place: 'Hilton Garden Inn',
    direction: '13 Calle 7-65, zona 9, Ciudad de Guatemala, 01009',
    urlLocation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.8170483632994!2d-90.51867336807939!3d14.600662819301427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a3d09f2f5067%3A0xd57f1276641df7b4!2sHilton%20Garden%20Inn%20Guatemala%20City!5e0!3m2!1ses!2sgt!4v1724165414042!5m2!1ses!2sgt" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade',
};
const basicQuestions = [
    {
        question: '¿Cómo puedo pagar?',
        answer: 'Los precios son en dólares americanos en efectivo. Aceptamos dólares, quetzales y euros. También aceptamos todas las tarjetas de crédito con un recargo del 10%.',
    },
    {
        question: 'Solo viajo yo, ¿puedo hacer el tour?',
        answer: 'Sí, tiene un suplemento de precio.',
    },
    {
        question: '¿Incluye el pago de admisiones?',
        answer: 'No, en general los lugares que visitamos, no requieren de pago alguno.',
    },
    {
        question: '¿Incluye comidas?',
        answer: 'No, ya que los horarios en los que se realizan los tours son fuera de esos tiempos.',
    },
    {
        question: '¿Cómo es el transporte?',
        answer: 'Habitualmente es en buses de Turismo de hasta 10 personas, pero si viajan 1 o 2 personas se hace en un Auto tipo sedan. Dependerá de la cantidad de personas que viajan.',
    },
];
// const cityTourDescription = "La ciudad de Guatemala es la cuarta capital de nuestro País y la más grande de centroamérica, durante la visita conoceremos: El Centro Histórico, El Palacio Nacional, sede del Gobierno, La Catedral Metropolitana, El Mercado Central de Artesanias, Frutas y Verduras, Cerrito del Carmen done fué traida la primera Ermita en 1,610, La Iglesia de Yurrita, La Avenida de La Reforma y de Las Américas también visitaremos Paseo Cayalá.";
const cityTourDescription = 'Durante la visita a la ciudad se puede apreciar el contraste entre la ciudad colonial, que se estableció en 1776, y la ciudad de hoy, dado por los centros comerciales, edificios de apartamentos y oficinas que se imponen para dar una visión contemporanea en el valle de la ciudad capital de Guatemala .';
const antiguaTourDescription = 'Antigua Guatemala una ciudad colonial establecida en 1545 fue destruida por una serie de terremotos en 1773, quedando desolada. Ha sido reconstruida y restaurada con los años dando lugar a lo que hoy es una de las ciudades más visitadas de Centroamérica. Declarada por UNESCO como Patrimonio Cultural de La Humanidad.';
const lagoTourDescription = 'Es considerado uno de los más bellos del mundo ya que sus aguas emergen 3 imponentes volcanes, el San Pedro, el Atitlán y el Tolimán. Al unirse, forman un maravilloso paisaje. Al llegar, se realizarás un recorrido en lancha por las aguas del Lago de Atitlán para conocer dos de sus pintorescos pueblos mayas, siendo Panajachel (el pueblo al que arriba el transporte) junto con Santiago Atitlán o San Juan La Laguna.';
const tikalTourDescription = 'Visite este maravilloso legado de la civilización Maya inmerso en la selva subtropical en la que al internarnos en ella iremos descubriendo las Ruinas de Templos, Centros Ceremoniales, Campos de Pelota Maya, Pirámides pudiendo escalar a alguno de ellos';
const chichiTourDescription = 'Es la cuna de la civilización Maya contemporanea. Sus habitantes celebran su misa en dialecto Maya, realizando ofrendas y oraciones en la Iglesia de Santo Tomás y El Calvario. Durante el recorrido se pueden comprar artesanïas en el mercado multicolor.';
const pacayaTourDescription = 'Tiene una altura de 2,500 metros sobre el nivel del mar. Se puede subir caminando o se puede rentar un caballo (no incluido) debido a que es de dificultad moderada. En la cima se puede observar los volcanes de Agua de Fuego y Acatenango (si está despejado). Se camina sobre lava seca de recientes erupciones y se visitan pozos de lava que emanan calor que permite dorar marshmallows.';
const cityHighlights = ['Plaza España', 'Torre del Reformador', 'Centro Cívico', 'Palacio Nacional de Gobierno', 'Casa Presidencial', 'Mercado Central de Artesanías', 'Mercado de Frutas y Verduras'];
const antiguaHighlights = ['Convento de Casa Santo Domingo', 'Iglesia de San Fransico', 'Tanque La Unión', 'Palacio de los Capitanes Generales', 'Catedral de la ciudad', 'Palacio del Ayundamiento', 'Arco de Santa Catalina', 'Iglesia de La Merced', 'Ruinas de El Carmen', 'Mercado de Artesanías', 'Museo del jade',];
const lagoHighlights = ['Mirador de los volcanes', 'Panajachel', 'Calle Santander', 'Muelles a la orilla del lago', 'San Juan La Laguna', 'Mercado de artesanías Mayas',];
const chichiHighlights = ['Mercado de frutas y verduras', 'Iglesia de Santo Tomás', 'Mercado de artesanías Mayas', 'Iglesia El Calvario', 'Hotel/Museo Santo Tomás',];
const tikalHighlights = ['Acrópolis Central', 'Templo El Gran Jaguar', 'Templo El Gran Sacerdote', 'Templo Serpiente Biséfala', 'Estelas Mayas', 'Campos de pelota',];
const pacayaHighlights = ['San Vicente Pacaya', 'Mantos de lava seca', 'Pozos de lava caliente', 'Cono Mackenney', 'Mirador de picos de volcanes Acatenango, Agua y Fuego',];
const acatenangoHighlights = ['Explosiones de ceniza', 'Explosiones de lava roja', 'Mirador de los volcanes Agua, Fuego y Pacaya',];
const hobbitenangoHighlights = ['Casas de Hobbits', 'Mano Dorada', 'Swing',];
const misticoHighlights = ['Centro ceremonial en San Andrés Itzapa', 'Ruinas mayas de Iximché'];
const hobbitenangoCxPictures = ['../assets/hobbitenangoCx1.jpeg',];
const cityCxPictures = ['../assets/cityCx1.jpeg', '../assets/cityCx2.jpeg', '../assets/cityCx3.jpeg', '../assets/cityCx4.jpeg', '../assets/cityCx5.jpeg', '../assets/cityCx6.jpeg',];
const antiguaCxPictures = ['../assets/antiguaCx1.jpeg', '../assets/antiguaCx2.jpeg', '../assets/antiguaCx3.jpeg', '../assets/antiguaCx4.jpeg',];
const lagoCxPictures = ['../assets/Ati2.jpg', '../assets/Ati3.jpg', '../assets/Ati4.jpg', '../assets/Ati5.jpg', '../assets/lagoCx1.jpeg', '../assets/lagoCx2.jpeg', '../assets/lagoCx3.jpeg', '../assets/lagoCx4.jpeg', '../assets/lagoCx5.jpeg',];
const tikalCxPictures = ['../assets/tikalCx1.jpeg', '../assets/tikalCx2.jpeg', '../assets/tikalCx3.jpeg',];
const pacayaCxPictures = ['../assets/pacayaCx6.jpeg', '../assets/Pacaya2.jpg', '../assets/Pacaya3.jpg', '../assets/Pacaya4.jpg', '../assets/Pacaya5.jpg', '../assets/Pacaya6.jpg',];

const cityTour = createTour('guate-city', 'City Tour', 4.5, 16,
        ['../assets/city1.jpg', '../assets/cityPaz.jpeg', '../assets/cityCatedral.jpg', '../assets/cityReformador.jpg', '../assets/cityTransmetro.jpg',],
        '../assets/precio45.png', 60,
        // 1,
        [90, 60, 50, 45, 45],
        3, cityTourDescription,
        ['7:00 - 10:00', '11:00 - 14:00',],
        cityHighlights,
        ['Transporte público', 'Pase de abordar', 'Guía Turístico'], ['Comidas', 'Propinas', 'Admisiones'], departureTransmetro, 'El 70% del tour es panorámico, pero se baja para conocer en: Plaza Central donde está el Palacio Nacional, Catedral Metropolitana y Mercado Central, También se baja en Iglesia del Cerrito del Carmen así como en Paseo Cayalá. La temperatura normal es entre 18 - 22 grados aunque varía dependiendo la estación.', cancellation_policy,
        basicQuestions,
        cityCxPictures,
        [
            {
                stars: 5,
                title: 'Buen viaje',
                name: 'Josh',
                date: 'Jan 2022',
                review: 'Una visita muy placentera a Guatemala City',
            },
            {
                stars: 5,
                title: 'Excelente tour',
                name: 'Gaby',
                date: 'Oct 2021',
                review: 'Nos la pasamos súper y el guía fue muy atento con nostros',
            },
        ]);
const antiguaGuatemala = createTour(
    'guate-antigua', // Alphanumeric - id
    'Antigua Guatemala', // String - title
    4.5, // Double - stars
    24, // Integer - amountReviews
    ['../assets/antigua.jpeg', '../assets/tanque.jpg', '../assets/capitanes.jpg', '../assets/capuchinas.jpg',], // Array - photos
    '../assets/precio45.png', // String - priceImg
    35, // Double - price
    // 3,
    [100, 75, 60, 50, 45],
    6, // Integer - time
    antiguaTourDescription, // String - description
    ['6:00 - 12:00', '8:00 - 14:00', '10:00 - 16:00', '12:00 - 18:00', '14:00 - 20:00', '16:00 - 20:00', 'Los horarios varian según disponibilidad'], // Array - horarios
    antiguaHighlights, // Array - highlights
    ['Transporte Colectivo', 'Guía Turístico'], // Array - included
    ['Comidas', 'Propinas'], // Array - notIncluded
    departureRegular, // String - departurePoint
    'Antigua Guatemala fué declarada como patrimonio cultural de la Humanidad por UNESCO, es una ciudad colonial establecida en el siglo VI, fué la capital del Reino de Guatemala y destruida por una serie de terremotos en 1773. Hoy es una maravillosa ciudad colonial y durante la esta visita conoceremos las Ruinas de Conventos, Monasterios, Iglesias y Palacios, entre ellos Palacio de Los Capitanes Generales, Iglesias de San Francisco y La Merced, Convento de Casa Santo Domingo, entre otras. Al pasear por las calles empedradas de Antigua, te maravillarás con su arquitectura colonial bien conservada. Las fachadas coloridas, los balcones adornados y las iglesias históricas te trasladarán a una época pasada. Podrás visitar la Catedral de San José, la Iglesia de La Merced y la Iglesia de San Francisco, entre muchas otras, todas ellas con una rica historia y hermosos detalles arquitectónicos. Además de su riqueza histórica, Antigua Guatemala ofrece una gran cantidad de atracciones y actividades para disfrutar. Puedes explorar los mercados locales, donde encontrarás artesanías tradicionales, textiles coloridos y deliciosa comida guatemalteca. También puedes visitar los museos para aprender sobre la historia y cultura de la región, como el Museo del Jade, el Museo Casa Santo Domingo y el Museo de Arte Colonial. Antigua es una ciudad colonial y sus calles son de piedra por lo que se debe llevar zapatos cómodos, porque el tour en general es caminando, el clima es similar al de la ciudad capital 18 - 22 grados celsius dependiendo de la estación puede variar.', // String - additionalInformation
    cancellation_policy, // String - cancellationPolicy
    basicQuestions, // Array -> Object -> String - FAQ
    antiguaCxPictures, // Array - travelerPhotos
    [
        {
            stars: 5,
            title: 'Hemorsa ciudad',
            name: 'Josh',
            date: 'Dec 2022',
            review: 'Todo estuvo perfecto, muy bonita la ciudad y la visita fue única.',
        },
        {
            stars: 5,
            title: 'Lovely tour',
            name: 'Gaby',
            date: 'Mar 2022',
            review: 'Quedé asombrada, no era lo que esperaba y me gustó mucho. Todo es muy colorido.',
        },
    ]); // Array -> Object - reviews
const lagoAtitlan = createTour(
        'guate-lago', // Alphanumeric - id
        'Lago de Atitlán', // String - title
        4.5, // Double - stars
        18, // Integer - amountReviews
        ['../assets/atitlan-lago.jpg', '../assets/Ati1.jpg', '../assets/Ati7.jpg', '../assets/Ati6.jpg'], // Array - photos
        '../assets/precio90.png', // String - priceImg
        110, // Double - price
        // 2,
        [175, 150, 125, 110, 110],
        12, // Integer - time
        lagoTourDescription, // String - description
        ['4:45 - 20:00'], // Array - horarios
        lagoHighlights, // Array - highlights
        ["Transporte colectivo", 'Guía Turístico', 'Paseo en Lancha'], // Array - included
        ["Comidas", 'Propinas'], // Array - notIncluded
        departureRegular, // String - whatToExpect
        'El Lago de Atitlán ha sido catalogado por National Geograpich como un de los más bellos del mundo, ya que los 3 volcanes que emergen de sus aguas y los 12 pueblos mayas que estan a su alrededor forman un maraviloso paisaje, durante el tour le llevaremos las poblaciones de Panajachel y de Santiago Atitlán, para esta última iremos en Lancha para cruzar de norte a sur, pasando en medio de los volcanes. por la tarde, regresaremos a la ciudad. Quedarás asombrado por la belleza natural que lo rodea. Sus aguas cristalinas reflejan los colores del cielo y las montañas circundantes, creando un espectáculo visual simplemente impresionante. Podrás disfrutar de vistas panorámicas desde diferentes miradores, como el Mirador de La Cruz o el Mirador de San Jorge. Es una experiencia que te sumergirá en la belleza natural y la cultura vibrante de Guatemala. Podrás disfrutar de vistas panorámicas, explorar los pueblos pintorescos, participar en actividades acuáticas y disfrutar de la serenidad que ofrece este increíble destino.', // String - additionalInformation
        cancellation_policy, // String - cancellationPolicy
        [
            ...basicQuestions,
            {
                question: '¿Incluye paseo en lancha? ¿Qué lugares visitamos?',
                answer: 'Sí incluye paseo en lancha y visitamos 1 pueblo: San Juan La Laguna o Santiago Atitlán hay que tomar en cuenta que la lancha es en servicio compartido por lo que la salida o regreso en la misma puede demorar.',
            },
        ], // Array -> Object // Array -> Object -> String - FAQ
        lagoCxPictures, // Array - travelerPhotos
        [], // Array // Array -> Object - reviews
    );
const chichicastenango = createTour(
        'guate-chichi', // Alphanumeric - id
        "Chichicastenango", // String - titleingtitle
        3, // Double - stars
        17,  // Integer - amountReviews
        ['../assets/chichi.jpg', '../assets/chichi2.jpg', '../assets/chichi3.jpg', '../assets/chichi4.jpg'],  // Array - photos
        '../assets/precio80.png', // String - priceImg
        110,  // Double - price
        // 2,
        [200, 100, 90, 80, 80],
        12,  // Integer - time
        chichiTourDescription,  // String - description
        ['7:00 - 17:00'], // Array - horarios
        chichiHighlights, // Array - highlights
        ["Transporte colectivo", 'Guía Turístico'],  // Array - included
        ['Comidas', 'Propinas'],  // Array - notIncluded
        departureRegular,  // String - departurePoint
        'Este tour opera únicamente los  jueves y domingos ya que esos son los días que los indígenas se reúnen para la celebración de sus costumbres y tradiciones en el día de mercado indigena. está ubicado a 3 horas de la ciudad y se visita en aproximadamente 2 horas por lo que aprovechando que el Lago de Atitlán está a 1 hora de distancia, les llevamos a una corta visita a panajachel, a orillas del lago. El mercado es un verdadero festín para los sentidos, con puestos llenos de textiles tradicionales, artesanías, cerámicas, máscaras, alimentos y más. Podrás explorar las calles estrechas llenas de colores y aromas, y tener la oportunidad de adquirir productos únicos y auténticos. La cultura indígena de Chichicastenango se hace evidente a través de sus festividades y tradiciones. ambién puedes presenciar danzas tradicionales y rituales mayas durante otras festividades importantes.',  // String - additionalInformation
        cancellation_policy,  // String - cancellationPolicy
        [
            ...basicQuestions
        ], // Array -> Obj // Array -> Object -> String - FAQ
        [],  // Array - travelerPhotos
        [], // Ar // Array -> Object - reviews
    );
const tikal = createTour(
        'guate-tikal', // Alphanumeric - id
        "Ruinas Mayas de Tikal", // String - title
        4.5, // Double - stars
        9, // Integer - amountReviews
        ['../assets/tikal2.jpg', '../assets/tikal-ruins.jpg', '../assets/tikal3.jpg', '../assets/tikal4.jpg',], // Array - photos
        '../assets/precio235.png', // String - priceImg
        250, // Double - price
        // 1,
        [275, 250, 245, 240, 235],
        18, // Integer - time
        tikalTourDescription, // String - description
        ['4:00 - 21:00'], // Array - horarios
        tikalHighlights, // Array - highlights
        ['Boleto aereo Guatemala City - Flores', 'Boleto aereo Flores - Guatemala', 'Traslado Flores - Tikal', 'Traslado Tikal - Flores', 'Guía Turístico', 'Tour Arqueológico'], // Array - included
        ['Alimentos','Traslado al aeropuerto', 'Admisión', 'Propinas'], // Array - notIncluded
        departureAirportGua, // String - departurePoint
        'Debido a que este tour no incluye transporte hacia el aeropuerto tendrá que llegar por su cuenta.', // String - additionalInformation
        'Las Ruinas Mayas se encuentran en una selva subtropical lluviosa por lo que en general hay calor húmedo, en general los caminos son planos no subidas, debe llevar ropa fresca y zapatos cómodos. Estamos orientados a servirle y hacerlo bien, en el caso de Tikal los boletos y tours deben ser prepagados para garantizar los mismos por lo cual los precios son definitivos y no hay reembolsos.', // String - cancellationPolicy
        [
            ...basicQuestions,
            {
                question: '¿Por qué sale tan temprano?',
                answer: 'Porque para hacer el tour de un día el avión sale a las 5:45am',
            },
            {
                question: '¿Cuánto tiempo estoy conociendo las ruinas?',
                answer: '4 horas aproximadamente, y aunque el parque nacional es muy muy grande, les llevamos a conocer lo más importante con nuestro guía certificado. Además el calor húmedo es un poco agotador y ya hemos comprobado que es tiempo suficiente para que usted quede satisfecho.',
            },
        ], // Array -> Object // Array -> Object -> String - FAQ
        tikalCxPictures, // Array - travelerPhotos
        [], // Array // Array -> Object - reviews
    );
const pacaya = createTour(
        'guate-pacaya', // Alphanumeric - id
        "Volcán Pacaya", // String - title
        3.5, // Double - stars
        16, // Integer - amountReviews
        ['../assets/Pacaya1.jpg', '../assets/Pacaya7.jpg', '../assets/Pacaya4.jpg', '../assets/Pacaya2.jpg'], // Array - photos
        '../assets/precio45.png', // String - priceImg
        60, // Double - price
        // 2,
        [150, 75, 75, 60, 50],
        6, // Integer - time
        pacayaTourDescription, // String - description
        ['7:00 - 13:00', '14:00 - 19:00',], // Array - horarios
        pacayaHighlights, // Array - highlights
        ['Transporte Colectivo', 'Guía Turístico',], // Array - included
        ['Admisión', 'Comidas', 'Paseo en Caballo', 'Propinas'], // Array - notIncluded
        departureRegular, // String - departurePoint
        'Te embarcarás en una caminata que te llevará a través de paisajes impresionantes. El sendero se adentra en la vegetación exuberante y te ofrece vistas panorámicas de la zona circundante. A medida que te acercas a la cima, podrás apreciar el cambio en el paisaje, pasando de árboles frondosos a campos de lava volcánica. Una vez que llegues al cráter del volcán Pacaya, podrás sentir el calor emanando de las fumarolas y ver la actividad volcánica en primera persona. Es posible que observes el flujo de lava o escuches el sonido de las explosiones, dependiendo de las condiciones volcánicas en ese momento. Además de la emoción de estar cerca de un volcán activo, el volcán Pacaya también ofrece impresionantes vistas panorámicas. Desde la cima, podrás disfrutar de vistas espectaculares del paisaje circundante, incluyendo otros volcanes y el lago de Amatitlán.', // String - additionalInformation
        cancellation_policy, // String - cancellationPolicy
        [
            ...basicQuestions,
            basicQuestions[2] = {
                question: 'Incluye el pago de admisiones?',
                answer: 'No, y el precio de la Admisión es de Q100.00 por persona la cual se paga directamente al ingresar.',
            },
        ], // Array -> Object -> String - FAQ
        pacayaCxPictures, // Array - travelerPhotos
        [], // Array -> Object - reviews
    );
const acatenango = createTour(
        'guate-acatenango', // Alphanumeric - id
        "Volcán Acatenango", // String - title
        3.5, // Double - stars
        18, // Integer - amountReviews
        ['../assets/volcan-acatenango.jpg', '../assets/acatenango2.jpg', '../assets/acatenango3.jpg', '../assets/acatenango4.jpg'], // Array - photos
        '../assets/precio85.png', // String - priceImg
        95, // Double - price
        // 2,
        [175, 125, 125, 115, 110],
        24, // Integer - time
        "¿Estás listo para desafiar tus límites y explorar la majestuosidad de la naturaleza? ¡Descubre la increíble experiencia de conquistar el Volcán Acatenango en Guatemala! Situado en el corazón de Guatemala, el Volcán Acatenango es una maravilla natural que te espera con sus imponentes paisajes y su energía volcánica única. A una altitud de 3,976 metros sobre el nivel del mar, este majestuoso coloso ofrece una experiencia inolvidable para los amantes de la aventura. A medida que te abres camino a través de los densos bosques y senderos empinados, serás testigo de la belleza natural de Guatemala. Observa la flora y fauna autóctonas mientras te acercas a la cima, donde serás recompensado con vistas panorámicas que te dejarán sin aliento. Pero la aventura no termina aquí. Al caer la noche, tendrás la oportunidad de presenciar un espectáculo único: la actividad volcánica del cercano Volcán de Fuego. Contempla la lava ardiente iluminando el cielo y siente la emoción de estar cerca de la poderosa fuerza de la naturaleza.  No importa si eres un entusiasta del senderismo experimentado o un amante de la naturaleza que busca una nueva aventura, el Volcán Acatenango te espera con los brazos abiertos. ¡Únete a nosotros y descubre la grandeza de Guatemala en todo su esplendor! ¡No pierdas la oportunidad de vivir una experiencia única en el Volcán Acatenango! Contacta con nosotros ahora mismo y prepárate para embarcarte en una aventura inolvidable.", // String - description
        ['6:00 - 16:00 (siguiente día)',], // Array - horarios
        acatenangoHighlights, // Array - highlights
        ['Transporte Colectivo', 'Guía Turístico', 'almuerzo', 'Cena', 'Desayuno',  'Sleeping bag', 'Tienda de acampar',], // Array - included
        [ 'Admisión', 'Propinas', 'Paseo en caballo',], // Array - notIncluded
        departureRegular, // String - departurePoint
        'El volcán de Pacaya tiene 2,500 mts sobre el nivel del mar, está situado a 1 hora de la ciudad de Guatemala, ascenderlo no requiere de mayor esfuerzo y puede realizarse en 1.5 horas caminando, o si lo prefiere puede hacerlo en caballo (no incluido), para llegar hasta la cima en donde se puede caminar sobre lava seca. Necesita llevar ropa y zapatos cómodos para su visita.', // String - additionalInformation
        cancellation_policy, // String - cancellationPolicy
        [
            ...basicQuestions,
        ], // Array -> Object -> String - FAQ
        [], // Array - travelerPhotos
        [], // Array -> Object - reviews
    );
const hobbitenango = createTour(
        'guate-hobbitenango', // Alphanumeric - id
        "Hobbitenango", // String - title
        3.5, // Double - stars
        17, // Integer - amountReviews
        ['../assets/hobbitenango1.jpg', '../assets/hobbitenango2.jpg', '../assets/hobbitenango3.jpg', '../assets/hobbitenango4.jpg'], // Array - photos
        '../assets/precio50.png', // String - priceImg
        60, // Double - price
        // 2,
        [100, 60, 60, 50, 50],
        6, // Integer - time
        "Disfrute de la maravillosa vista de los volcanes desde las montañas de Hobbitenango, tambien podra conocer las casas de los Hobbits incrustradas en las montañas, ademas de llegar a tomarse la foto en la Mano de oro que emerge de la tierra, Hobbitenango tiene muchas atracciones para la familia.", // String - description
        ['8:00 - 13:00', '14:00 - 19:00',], // Array - horarios
        hobbitenangoHighlights, // Array - highlights
        ['Transporte Colectivo',], // Array - included
        [ 'Admisión', 'Alimentos', 'Propinas',], // Array - notIncluded
        departureRegular, // String - departurePoint
        'El clima es frio por la mañana por lo que se recomenda llevar un Sweter, pero en la medida que camina por los senderos, entrara en calor.', // String - additionalInformation
        cancellation_policy, // String - cancellationPolicy
        [
            ...basicQuestions,
        ], // Array -> Object -> String - FAQ
        hobbitenangoCxPictures, // Array - travelerPhotos
        [], // Array -> Object - reviews
    );

const mistico = createTour(
    'guate-mistico', // Alphanumeric - id
    "Tour Mistico", // String - title
    4, // Double - stars
    12, // Integer - amountReviews
    ['../assets/mistico.jpg', '../assets/mistico2.jpg', '../assets/mistico3.jpg', '../assets/mistico4.jpg'], // Array - photos
    '../assets/precio65.png', // String - priceImg
    95, // Double - price
    // 2,
    [225, 125, 125, 115, 110],
    7, // Integer - time
    "Guatemala es rica en costumbres y tradiciones y una de las expresiones de la cultura maya son las Ceremonias que realizan en lugares sagrados entre los que destacan San Andrés Itzapa, donde se encuentra uno de los centros ceremoniales mas grandes del País, pero tambien se pueden observar en las Ruinas de lo que fué la primera capital de Guatemala: Iximché, lugar que ha sido punto de reunión de Culturas Indigenas de Latinoamérica, visite y conozca más de Guatemala en este maravilloso Tour Místico.", // String - description
    ['7:00 - 13:00',], // Array - horarios
    misticoHighlights, // Array - highlights
    ['Transporte Colectivo', 'Guía Turístico',], // Array - included
    ['Admisiones', 'Alimentos', 'Propinas',], // Array - notIncluded
    departureRegular, // String - pickUpPoint
    'Se requiere ropa y zapatos cómodos para caminar en el sitio arqueológico de Iximché, pero puede llevar un sweter por si hace frio depende la epoca del año. consultar.', // String - additionalInformation
    cancellation_policy, // String - cancellationPolicy
    [
        ...basicQuestions,
    ], // Array -> Object -> String - FAQ
    [], // Array - travelerPhotos
    [], // Array -> Object - reviews
);
// -------------------
// Tours desde Antigua
// -------------------
const antiguaCityTour = createTour('antigua-city', 'City Tour', 4.5, 16,
        ['../assets/city1.jpg', '../assets/guatemala-city.jpg', '../assets/yurrita.jpg', '../assets/cayala.jpg', '../assets/catedral.jpg'],
        '../assets/precio70.png', // String - priceImg
        100,
        // 2,
        [150, 99, 90, 80, 70],
        3.5, cityTourDescription,
        ['9:00 - 14:00'], // Array - horarios
        ['Avenida La Reforma', 'Iglesia Yurrita', 'Centro Cívico', 'Palacio Nacional de Gobierno', 'Casa Presidencial', 'Cerrito del Carmen',], // Array - highlights
        ['Transportation', 'Guía Turístico'], ['Comidas', 'Propinas', 'Admisións'], departureAntigua, 'El 70% del tour es panorámico, pero se baja para conocer en: Plaza Central donde está el Palacio Nacional, Catedral Metropolitana y Mercado Central, También se baja en Iglesia del Cerrito del Carmen así como en Paseo Cayalá. La temperatura normal es entre 18 - 22 grados aunque varía dependiendo la estación.', cancellation_policy,
        basicQuestions,
        cityCxPictures,
        [
            {
                stars: 5,
                title: 'Buen viaje',
                name: 'Josh',
                date: 'Jan 2022',
                review: 'Una visita muy placentera a Guatemala City',
            },
            {
                stars: 5,
                title: 'Excelente tour',
                name: 'Gaby',
                date: 'Oct 2021',
                review: 'Nos la pasamos súper y el guía fue muy atento con nostros',
            },
        ]);
const antiguaAntiguaGuatemala = createTour(
    'antigua-antigua', // Alphanumeric - id
    'Antigua Guatemala', // String - title
    5, // Double - stars
    32, // Integer - amountReviews
    ['../assets/antigua.jpeg', '../assets/tanque.jpg', '../assets/capitanes.jpg', '../assets/capuchinas.jpg',], // Array - photos
    '../assets/precio15.png', // String - priceImg
    30, // Double - price
    // 1,
    [45, 30, 25, 20, 15],
    3, // Integer - time
    antiguaTourDescription, // String - description
    ['9:00 - 12:00', '15:00 - 18:00',], // Array - horarios
    antiguaHighlights, // Array - highlights
    ['Guía Turístico'], // Array - included
    ['Comidas', 'Propinas'], // Array - notIncluded
    departureAntigua, // String - departurePoint
    'Antigua Guatemala fué declarada como patrimonio cultural de la Humanidad por UNESCO, es una ciudad colonial establecida en el siglo VI, fué la capital del Reino de Guatemala y destruida por una serie de terremotos en 1773. Hoy es una maravillosa ciudad colonial y durante la esta visita conoceremos las Ruinas de Conventos, Monasterios, Iglesias y Palacios, entre ellos Palacio de Los Capitanes Generales, Iglesias de San Francisco y La Merced, Convento de Casa Santo Domingo, entro otras Antigua es una ciudad colonial y sus calles son de piedra por lo que se debe llevar zapatos cómodos, porque el tour en general es caminando, el clima es similar al de la ciudad capital 18 - 22 grados celsius dependiendo de la estación puede variar.', // String - additionalInformation
    cancellation_policy, // String - cancellationPolicy
    basicQuestions, // Array -> Object -> String - FAQ
    antiguaCxPictures, // Array - travelerPhotos
    [
        {
            stars: 5,
            title: 'Hemorsa ciudad',
            name: 'Josh',
            date: 'Dec 2022',
            review: 'Todo estuvo perfecto, muy bonita la ciudad y la visita fue única.',
        },
        {
            stars: 5,
            title: 'Lovely tour',
            name: 'Gaby',
            date: 'Mar 2022',
            review: 'Quedé asombrada, no era lo que esperaba y me gustó mucho. Todo es muy colorido.',
        },
    ]); // Array -> Object - reviews
const antiguaLagoAtitlan = createTour(
        'antigua-lago', // Alphanumeric - id
        'Lago de Atitlán', // String - title
        4.5, // Double - stars
        16, // Integer - amountReviews
        ['../assets/atitlan-lago.jpg', '../assets/Ati1.jpg', '../assets/Ati7.jpg', '../assets/Ati6.jpg'], // Array - photos
        '../assets/precio60.png', // String - priceImg
        75, // Double - price
        // 1,
        [80, 75, 70, 65, 60],
        12, // Integer - time
        lagoTourDescription, // String - description
        ['8:00 - 18:00',], // Array - horarios
        lagoHighlights, // Array - highlights
        ["Transporte", 'Guía Turístico', 'Paseo en Lancha'], // Array - included
        ["Comidas", 'Propinas'], // Array - notIncluded
        departureAntigua, // String - departurePoint
        'El Lago de Atitlán ha sido catalogado por National Geograpich como un de los más bellos del mundo, ya que los 3 volcanes que emergen de sus aguas y los 12 pueblos mayas que estan a su alrededor forman un maraviloso paisaje, durante el tour le llevaremos las poblaciones de Panajachel y de Santiago Atitlán, para esta última iremos en Lancha para cruzar de norte a sur, pasando en medio de los volcanes. por la tarde, regresaremos a la ciudad. Es una experiencia que te sumergirá en la belleza natural y la cultura vibrante de Guatemala. Podrás disfrutar de vistas panorámicas, explorar los pueblos pintorescos, participar en actividades acuáticas y disfrutar de la serenidad que ofrece este increíble destino.', // String - additionalInformation
        cancellation_policy, // String - cancellationPolicy
        [
            ...basicQuestions,
            {
                question: '¿Incluye paseo en lancha? ¿Qué lugares visitamos?',
                answer: 'Sí incluye paseo en lancha y visitamos 1 pueblo: San Juan La Laguna o Santiago Atitlán hay que tomar en cuenta que la lancha es en servicio compartido por lo que la salida o regreso en la misma puede demorar.',
            },
        ], // Array -> Object // Array -> Object -> String - FAQ
        lagoCxPictures, // Array - travelerPhotos
        [], // Array // Array -> Object - reviews
    );
const antiguaChichicastenango = createTour(
        'antigua-chichi', // Alphanumeric - id
        "Chichicastenango", // String - titleingtitle
        4.5, // Double - stars
        19,  // Integer - amountReviews
        ['../assets/chichi.jpg', '../assets/chichi2.jpg', '../assets/chichi3.jpg', '../assets/chichi4.jpg'],  // Array - photos
        '../assets/precio65.png', // String - priceImg
        99,  // Double - price
        // 2,
        [110, 99, 85, 75, 65],
        12,  // Integer - time
        chichiTourDescription,  // String - description
        ['8:00 - 18:00',], // Array - horarios
        chichiHighlights, // Array - highlights
        ["Transporte Colectivo", 'Guía Turístico'],  // Array - included
        ['Comidas', 'Propinas'],  // Array - notIncluded
        departureAntigua,  // String - departurePoint.
        'Este tour opera únicamente los Jueves y domingos ya que esos son los días que los indígenas se reúnen para la celebración de sus costumbres y tradiciones en el día de mercado indigena. está ubicado a 3 horas de la ciudad y se visita en aproximadamente 2 horas por lo que aprovechando que el Lago de Atitlán está a 1 hora de distancia, les llevamos a una corta visita a panajachel, a orillas del lago. El mercado es un verdadero festín para los sentidos, con puestos llenos de textiles tradicionales, artesanías, cerámicas, máscaras, alimentos y más. Podrás explorar las calles estrechas llenas de colores y aromas, y tener la oportunidad de adquirir productos únicos y auténticos.',  // String - additionalInformation
        cancellation_policy,  // String - cancellationPolicy
        [], // Array -> Ob // Array -> Object -> String - FAQ
        [],  // Array - travelerPhotos
        [], // Ar // Array -> Object - reviews
    );
const antiguaTikal = createTour(
        'antigua-tikal', // Alphanumeric - id
        "Ruinas Mayas de Tikal", // String - title
        4.5, // Double - stars
        11, // Integer - amountReviews
        ['../assets/tikal2.jpg', '../assets/tikal-ruins.jpg', '../assets/tikal3.jpg', '../assets/tikal4.jpg',], // Array - photos
        '../assets/precio245.png', // String - priceImg
        275, // Double - price
        // 2,
        [275, 275, 275, 245, 245],
        18, // Integer - time
        tikalTourDescription, // String - description
        ['4:00 - 21:00'], // Array - horarios
        tikalHighlights, // Array - highlights
        ['Boleto aereo Guatemala City - Flores', 'Boleto aereo Flores - Guatemala', 'Traslado Flores - Tikal', 'Traslado Tikal - Flores', 'Guía Turístico', 'Tour Arqueológico'], // Array - included
        ['Desayuno','Traslado al aeropuerto', 'Admisión', 'Almuerzo campestre', 'Bebidas', 'Propinas'], // Array - notIncluded
         departureAirportGua, // String - departurePoint
        'Las Ruinas Mayas se encuentran en una selva subtropical lluviosa por lo que en general hay calor húmedo, en general los caminos son planos no subidas, debe llevar ropa fresca y zapatos cómodos. Se visitará una selva subtropiacal lluviosa. Se recomienda utilizar ropa cómoda', // String - additionalInformation
        // 'Estamos orientados a servirle y hacerlo bien, en el caso de Tikal los boletos y tours deben ser prepagados para garantizar los mismos por lo cual los precios son definitivos y no hay reembolsos.', // String - cancellationPolicy
        cancellation_policy,
        [
            ...basicQuestions,
            {
                question: '¿Por qué sale tan temprano?',
                answer: 'Porque para hacer el tour de un día el avión sale a las 5:45am',
            },
            {
                question: '¿Cuánto tiempo estoy conociendo las ruinas?',
                answer: '4 horas aproximadamente, y aunque el parque nacional es muy muy grande, les llevamos a conocer lo más importante con nuestro guía certificado. Además el calor húmedo es un poco agotador y ya hemos comprobado que es tiempo suficiente para que usted quede satisfecho.',
            },
        ], // Array -> Object // Array -> Object -> String - FAQ
        tikalCxPictures, // Array - travelerPhotos
        [], // Array // Array -> Object - reviews
    );
const antiguaPacaya = createTour(
        'antigua-pacaya', // Alphanumeric - id
        "Volcán Pacaya", // String - title
        3.5, // Double - stars
        16, // Integer - amountReviews
        ['../assets/Pacaya1.jpg', '../assets/Pacaya7.jpg', '../assets/Pacaya4.jpg', '../assets/Pacaya2.jpg'], // Array - photos
        '../assets/precio35.png', // String - priceImg
        35, // Double - price
        // 2,
        [35, 35, 35, 35, 35],
        6, // Integer - time
        pacayaTourDescription, // String - description
        ['6:00 - 12:00', '14:00 - 20:00'], // Array - horarios
        pacayaHighlights, // Array - highlights
        ['Transporte Colectivo', 'Guía Turístico',], // Array - included
        ['Admisión', 'Comidas', 'Paseo en caballo', 'Propinas'], // Array - notIncluded
        departureAntigua, // String - departurePoint
        'Te embarcarás en una caminata que te llevará a través de paisajes impresionantes. El sendero se adentra en la vegetación exuberante y te ofrece vistas panorámicas de la zona circundante. A medida que te acercas a la cima, podrás apreciar el cambio en el paisaje, pasando de árboles frondosos a campos de lava volcánica. Una vez que llegues al cráter del volcán Pacaya, podrás sentir el calor emanando de las fumarolas y ver la actividad volcánica en primera persona. Es posible que observes el flujo de lava o escuches el sonido de las explosiones, dependiendo de las condiciones volcánicas en ese momento. Además de la emoción de estar cerca de un volcán activo, el volcán Pacaya también ofrece impresionantes vistas panorámicas. Desde la cima, podrás disfrutar de vistas espectaculares del paisaje circundante, incluyendo otros volcanes y el lago de Amatitlán.', // String - additionalInformation
        cancellation_policy, // String - cancellationPolicy
        [
            ...basicQuestions,
            basicQuestions[2] = {
                question: 'Incluye el pago de admisiones?',
                answer: 'No, y el precio de la Admisión es de Q100.00 por persona la cual se paga directamente al ingresar.',
            },
        ], // Array -> Object -> String - FAQ
        ['../assets/Pacaya2.jpg', '../assets/Pacaya3.jpg', '../assets/Pacaya4.jpg', '../assets/Pacaya5.jpg', '../assets/Pacaya6.jpg'], // Array - travelerPhotos
        [], // Array -> Object - reviews
    );
const antiguaAcatenango = createTour(
        'antigua-acatenango', // Alphanumeric - id
        "Volcán Acatenango", // String - title
        3.5, // Double - stars
        18, // Integer - amountReviews
        ['../assets/volcan-acatenango.jpg', '../assets/acatenango2.jpg', '../assets/acatenango3.jpg', '../assets/acatenango4.jpg'], // Array - photos
        '../assets/precio45.png', // String - priceImg
        55, // Double - price
        // 1,
        [55, 55, 45, 45, 45],
        24, // Integer - time
        "¿Estás listo para desafiar tus límites y explorar la majestuosidad de la naturaleza? ¡Descubre la increíble experiencia de conquistar el Volcán Acatenango en Guatemala! Situado en el corazón de Guatemala, el Volcán Acatenango es una maravilla natural que te espera con sus imponentes paisajes y su energía volcánica única. A una altitud de 3,976 metros sobre el nivel del mar, este majestuoso coloso ofrece una experiencia inolvidable para los amantes de la aventura. A medida que te abres camino a través de los densos bosques y senderos empinados, serás testigo de la belleza natural de Guatemala. Observa la flora y fauna autóctonas mientras te acercas a la cima, donde serás recompensado con vistas panorámicas que te dejarán sin aliento. Pero la aventura no termina aquí. Al caer la noche, tendrás la oportunidad de presenciar un espectáculo único: la actividad volcánica del cercano Volcán de Fuego. Contempla la lava ardiente iluminando el cielo y siente la emoción de estar cerca de la poderosa fuerza de la naturaleza.  No importa si eres un entusiasta del senderismo experimentado o un amante de la naturaleza que busca una nueva aventura, el Volcán Acatenango te espera con los brazos abiertos. ¡Únete a nosotros y descubre la grandeza de Guatemala en todo su esplendor! ¡No pierdas la oportunidad de vivir una experiencia única en el Volcán Acatenango! Contacta con nosotros ahora mismo y prepárate para embarcarte en una aventura inolvidable.", // String - description
        ['8:30 - 15:00 (al siguiente día)',], // Array - horarios
        acatenangoHighlights, // Array - highlights
        ['Transporte Colectivo', 'Guía Turístico', 'Camp Tent', 'Dinner', 'Breakfast', 'Sleeping bag'], // Array - included
        ['Admisión', 'Propinas', 'Viaje en Caballo',], // Array - notIncluded
        departureAntigua, // String - departurePoint
        'El volcán de Pacaya tiene 2,500 mts sobre el nivel del mar, está situado a 1 hora de la ciudad de Guatemala, ascenderlo no requiere de mayor esfuerzo y puede realizarse en 1.5 horas caminando, o si lo prefiere puede hacerlo en caballo (no incluido), para llegar hasta la cima en donde se puede caminar sobre lava seca. Necesita llevar ropa y zapatos cómodos para su visita.', // String - additionalInformation
        cancellation_policy, // String - cancellationPolicy
        [
            ...basicQuestions,
        ], // Array -> Object -> String - FAQ
        [], // Array - travelerPhotos
        [], // Array -> Object - reviews
    );
const antiguaHobbitenango = createTour(
        'antigua-hobbitenango', // Alphanumeric - id
        "Hobbitenango", // String - title
        3.5, // Double - stars
        17, // Integer - amountReviews
        ['../assets/hobbitenango1.jpg', '../assets/hobbitenango2.jpg', '../assets/hobbitenango3.jpg', '../assets/hobbitenango4.jpg'], // Array - photos
        '../assets/precio40.png', // String - priceImg
        45, // Double - price
        // 1,
        [45, 45, 40, 35, 40,],
        6, // Integer - time
        "Disfrute de la maravillosa vista de los volcanes desde las montañas de Hobbitenango, tambien podra conocer las casas de los Hobbits incrustradas en las montañas, ademas de llegar a tomarse la foto en la Mano de oro que emerge de la tierra, Hobbitenango tiene muchas atracciones para la familia.", // String - description
        ['9:00 - 13:00', '14:00 - 18:00'], // Array - horarios
        hobbitenangoHighlights, // Array - highlights
        ['Transporte Colectivo',], // Array - included
        [ 'Admisión', 'Alimentos', 'Propinas',], // Array - notIncluded
        departureAntigua, // String - departurePoint
        'El clima es frio por la mañana por lo que se recomenda llevar un Sweter, pero en la medida que camina por los senderos, entrara en calor.', // String - additionalInformation
        cancellation_policy, // String - cancellationPolicy
        [
            ...basicQuestions,
        ], // Array -> Object -> String - FAQ
        hobbitenangoCxPictures, // Array - travelerPhotos
        [], // Array -> Object - reviews
    );

    const antiguaMistico = createTour(
        'antigua-mistico', // Alphanumeric - id
        "Tour Mistico", // String - title
        4, // Double - stars
        12, // Integer - amountReviews
        ['../assets/mistico.jpg', '../assets/mistico2.jpg', '../assets/mistico3.jpg', '../assets/mistico4.jpg'], // Array - photos
        '../assets/precio65.png', // String - priceImg
        95, // Double - price
        // 2,
        [125, 95, 85, 75, 65,],
        7, // Integer - time
        "Guatemala es rica en costumbres y tradiciones y una de las expresiones de la cultura maya son las Ceremonias que realizan en lugares sagrados entre los que destacan San Andrés Itzapa, donde se encuentra uno de los centros ceremoniales mas grandes del País, pero tambien se pueden observar en las Ruinas de lo que fué la primera capital de Guatemala: Iximché, lugar que ha sido punto de reunión de Culturas Indigenas de Latinoamérica, visite y conozca más de Guatemala en este maravilloso Tour Místico.", // String - description
        ['9:00 - 15:00',], // Array - horarios
        misticoHighlights, // Array - highlights
        ['Transporte Colectivo', 'Guía Turístico',], // Array - included
        ['Admisiones', 'Alimentos', 'Propinas',], // Array - notIncluded
        departureAntigua, // String - pickUpPoint
        'Se requiere ropa y zapatos cómodos para caminar en el sitio arqueológico de Iximché, pero puede llevar un sweter por si hace frio depende la epoca del año. consultar.', // String - additionalInformation
        cancellation_policy, // String - cancellationPolicy
        [
            ...basicQuestions,
        ], // Array -> Object -> String - FAQ
        [], // Array - travelerPhotos
        [], // Array -> Object - reviews
    );
// -------------------
// Tours desde Flores
// -------------------
const floresTikal = createTour(
    'flores-tikal', // Alphanumeric - id
    'Tikal', // String - title
    4.5, // Double - stars
    25, // Integer - amountReviews 
    ['../assets/tikal-ruins.jpg', '../assets/tikal2.jpg', '../assets/tikal3.jpg', '../assets/tikal4.jpg'], // Array - photos
    '../assets/precio35.png', // String - priceImg
    45, // Double - price
    // 1,
    [60, 45, 40, 35, 35],
    8, // Integer - time
    'El tour al Parque Tikal es una experiencia inolvidable que te lleva a descubrir la majestuosidad y el misterio de una de las ciudades mayas más importantes de la antigüedad. Situado en medio de la selva tropical de Guatemala, el Parque Nacional Tikal es un tesoro arqueológico y un sitio declarado Patrimonio de la Humanidad por la UNESCO. A medida que exploras el parque junto a un guía experto, te adentrarás en la historia y cultura maya. Te maravillarás con la arquitectura monumental de las estructuras, como el Templo del Gran Jaguar y el Templo de las Máscaras, que se alzan imponentes en medio de la vegetación.', // String - description
    ['4:30 - 14:00', '6:00 - 13:00', '8:00 - 15:00', '10:00 - 16:00',], // Array - horarios
    tikalHighlights, // Array - highlights
    ['Traslados Frs - Tikal - Frs', 'Guía Turístico', 'Tour Arqueológico'], // Array - included
    ['Admisión al parque', 'Almuerzo campestre', 'Bebidas', 'Propinas'], // Array - notIncluded
    departureFlores, // String - departurePoint
    'Las Ruinas Mayas se encuentran en una selva subtropical lluviosa por lo que en general hay calor húmedo, en general los caminos son planos no subidas, debe llevar ropa fresca y zapatos cómodos.', // String - additionalInformation
    cancellation_policy, // String - cancellationPolicy
    [
        ...basicQuestions,
        {
            question: '¿Por qué sale tan temprano?',
            answer: 'Porque para hacer el tour de un día el avión sale a las 5:45am',
        },
        {
            question: '¿Cuánto tiempo estoy conociendo las ruinas?',
            answer: '4 horas aproximadamente, y aunque el parque nacional es muy muy grande, les llevamos a conocer lo más importante con nuestro guía certificado. Además el calor húmedo es un poco agotador y ya hemos comprobado que es tiempo suficiente para que usted quede satisfecho.',
        },
    ], // Array -> Object -> String - FAQ
    ['../assets/tikal.jpg'], // Array - travelerPhotos
    [
        {
            stars: 5,
            title: 'Genial!',
            name: 'Juan José Flores',
            date: 'Mayo de 2023',
            review: 'El tour estuvo muy bien con un guia muy amable y conocedor del tema',
        }
    ], // Array -> Object - reviews
);
const floresAmanecerTikal = createTour(
    'flores-amanecer-tikal', // Alphanumeric - id
    'Amanecer en Tikal', // String - title
    4, // Double - stars
    20, // Integer - amountReviews 
    ['../assets/amanecerTikal.jpg', '../assets/tikalAmanecer2.jpg', '../assets/tikalAmanecer3.jpg', '../assets/tikalAmanecer4.jpg',], // Array - photos
    '../assets/precio60.png', // String - priceImg
    75, // Double - price
    // 2,
    [95, 75, 70, 65, 60],
    8, // Integer - time
    'El tour comienza temprano en la mañana para aprovechar al máximo la visita. Al llegar al parque, te sumergirás en un entorno natural exuberante, donde serás recibido por las impresionantes pirámides, templos y plazas que conforman la antigua ciudad maya de Tikal.    ', // String - description
    ['3:00 - 12:30',], // Array - horarios
    tikalHighlights, // Array - highlights
    ['Guia', 'Transporte'], // Array - included
    ['Admisión', 'Propinas', 'Alimentos'], // Array - notIncluded
    departureFlores, // String - departurePoint
    'Los tours al amanecer en Tikal suelen comenzar antes del amanecer, para que puedas llegar al sitio arqueológico a tiempo para presenciar el espectáculo del amanecer. Esto significa que tendrás que despertarte temprano y estar listo para embarcarte en la aventura. Un tour en Tikal con vista al amanecer es una experiencia mágica que te permite presenciar la belleza y la energía especial del amanecer en este antiguo sitio maya. Aquí tienes algunas cosas que puedes esperar de este tipo de tour', // String - additionalInformation
    cancellation_policy, // String - cancellationPolicy
    [
        ...basicQuestions,
    ], // Array -> Object -> String - FAQ
    [], // Array - travelerPhotos
    [
        {
            stars: 5,
            title: 'Deslumbrante',
            name: 'Josué Martínez',
            date: 'Mayo de 2023',
            review: 'El amanecer es espectacular',
        }
    ], // Array -> Object - reviews
);
const floresYaxha = createTour(
    'flores-yaxha', // Alphanumeric - id
    'Yaxhá', // String - title
    4, // Double - stars
    9, // Integer - amountReviews 
    ['../assets/yaxha.jpg', '../assets/yaxha2.jpg', '../assets/yaxha3.jpg', '../assets/yaxha4.jpg',], // Array - photos
    '../assets/precio35.png', // String - priceImg
    75, // Double - price
    // 1,
    [60, 50, 45, 40, 35,],
    7, // Integer - time
    'Un viaje a Yaxha te llevará a explorar una antigua ciudad maya en medio de la selva de Guatemala. Con vistas impresionantes y una rica historia, podrás recorrer sus pirámides, templos y plazas. Disfruta de la belleza natural de los lagos y la exuberante vegetación que rodea el sitio arqueológico. Sumérgete en la cultura maya mientras aprendes sobre sus tradiciones y descubres la importancia de Yaxha en la antigua civilización. ¡Una experiencia fascinante llena de aventura y conocimiento te espera en Yaxha!', // String - description
    ['12:00 - 19:00',], // Array - horarios
    ['Ruinas del Grupo A, B y C', 'Templo 216', 'Acrópolis norte', 'Grupo Malher', 'Calzadas y Avenidas', 'Mirador de las Lagunas Sacnab',], // Array - highlights
    ['Transporte', 'Entrada', 'Guia'], // Array - included
    ['Propinas', 'Alimentos'], // Array - notIncluded
    departureFlores, // String - departurePoint
    'Es recomendable llevar ropa y calzado cómodos, ya que caminarás en terreno irregular. También es aconsejable llevar protección solar, repelente de insectos, agua y un sombrero o gorra. Al visitar Yaxhá, es importante respetar el patrimonio cultural y natural. No está permitido escalar estructuras que no estén habilitadas para ello ni dañar o llevarse objetos del sitio. Ten en cuenta que la selva puede ser calurosa y húmeda, por lo que es recomendable llevar ropa ligera y mantenerse hidratado durante la visita. Además, se sugiere llevar una cámara fotográfica para capturar los hermosos paisajes y momentos especiales.', // String - additionalInformation
    cancellation_policy, // String - cancellationPolicy
    [
        ...basicQuestions,
    ], // Array -> Object -> String - FAQ
    ['../assets/yaxha5.jpg', '../assets/yaxha7.jpg'], // Array - travelerPhotos
    [
        {
            stars: 4.5,
            title: 'Yaxha es magnífica',
            name: 'Gaby Martinez',
            date: 'Mayo de 2023',
            review: 'Atardecer genial',
        },
    ], // Array -> Object - reviews
);
const floresCeibal = createTour(
    'flores-ceibal', // Alphanumeric - id
    'Ceibal', // String - title
    3.5, // Double - stars
    14, // Integer - amountReviews 
    ['../assets/ceibal.jpg', '../assets/ceibal2.jpg', '../assets/ceibal3.jpg', '../assets/ceibal4.jpg',], // Array - photos
    '../assets/precio145.png', // String - priceImg
    175, // Double - price
    // 2,
    [350, 175, 165, 155, 145],
    8, // Integer - time
    'Una visita a Ceibal te llevará a explorar un fascinante sitio arqueológico de la antigua civilización maya en Guatemala. Situado en medio de la selva de Petén, Ceibal ofrece impresionantes estructuras y artefactos que revelan la grandeza de esta antigua ciudad. Al recorrer Ceibal, podrás maravillarte con las imponentes pirámides, los templos y las plazas que han resistido el paso del tiempo. Admira los tallados en piedra, los altares y las estelas que cuentan historias de la antigua civilización maya. El entorno natural de Ceibal también es cautivador, con su densa vegetación y la tranquilidad que lo rodea. Podrás escuchar los sonidos de la selva y disfrutar de la riqueza de la flora y fauna que habita en la zona.', // String - description
    [], // Array - horarios
    ['Grupo A', 'Grupo C', 'Grupo D', 'La Pirámide Circular', 'El Altar Zoomorfo', 'El Jugo de Pelota', 'Las Calzadas I y II',], // Array - highlights
    ['Transporte', 'Entrada', 'Guia'], // Array - included
    ['Propinas', 'Alimentos'], // Array - notIncluded
    departureFlores, // String - departurePoint
    'Una visita a Ceibal te transportará a través del tiempo, permitiéndote sumergirte en la grandiosa civilización maya y apreciar la belleza de este lugar histórico. ¡Prepárate para una experiencia inolvidable llena de descubrimientos y asombro en Ceibal!', // String - additionalInformation
    cancellation_policy, // String - cancellationPolicy
    [
        ...basicQuestions,
    ], // Array -> Object -> String - FAQ
    [], // Array - travelerPhotos
    [
        {
            star: 4,
            title: 'Ceibal fue único',
            name: 'Steve Johnson',
            date: 'Marzo de 2023',
            review: 'Una experiencia única, pude disfrutar mucho del entorno',
        },
    ], // Array -> Object - reviews
);
const floresAguateca = createTour(
    'flores-aguateca', // Alphanumeric - id
    'Aguateca', // String - title
    4.5, // Double - stars
    16, // Integer - amountReviews 
    ['../assets/aguateca.jpg', '../assets/aguateca2.jpg', '../assets/aguateca3.jpg', '../assets/aguateca4.jpg',], // Array - photos
    '../assets/precio145.png', // String - priceImg
    175, // Double - price
    // 2,
    [350, 175, 165, 155, 145],
    8, // Integer - time
    'Una visita a Aguateca te sumergirá en la majestuosidad de un antiguo sitio arqueológico maya ubicado en Guatemala. Rodeado de exuberante vegetación y situado en lo alto de una colina, Aguateca ofrece una experiencia única para los amantes de la historia y la arqueología. Al explorar Aguateca, podrás maravillarte con las estructuras antiguas, incluyendo templos, palacios y plazas. Podrás admirar las impresionantes ruinas que han resistido el paso del tiempo y que muestran el ingenio arquitectónico y artístico de la civilización maya.', // String - description
    [], // Array - horarios
    ['Mirador Del Peñón', 'Caminata por La Falla geológica', 'Plazas de estructuras y estelas',], // Array - highlights
    ['Transporte', 'Guia', 'Entrada'], // Array - included
    ['Propinas', 'Alimentos', 'Bebidas'], // Array - notIncluded
    departureFlores, // String - whatToExpect
    'Al visitar Aguateca, asegúrate de llevar ropa y calzado cómodos, así como protección solar y repelente de insectos. También se sugiere llevar agua y algo de comida, ya que el acceso a servicios de alimentos puede ser limitado. Una visita a Aguateca te transportará en el tiempo y te permitirá conectarte con la grandeza de la antigua civilización maya. Disfruta de la tranquilidad, la belleza natural y el legado histórico que Aguateca tiene para ofrecer. A medida que te adentres en el sitio, podrás apreciar los grabados y relieves en piedra que cuentan historias de la vida cotidiana, los rituales y las creencias de los antiguos habitantes de Aguateca. También podrás disfrutar de las vistas panorámicas de los alrededores, incluyendo ríos y paisajes naturales que realzan la belleza del lugar.', // String - additionalInformation
    cancellation_policy, // String - cancellationPolicy
    [
        ...basicQuestions,
    ], // Array -> Object -> String - FAQ
    [], // Array - travelerPhotos
    [
        {
            stars: 3,
            title: 'Aguateca fue único',
            date: 'Abril de 2023',
            name: 'Steve Johnson',
            review: 'Genial',
        },
    ], // Array -> Object - reviews
);
const floresCraterAzul = createTour(
    'flores-crater-azul', // Alphanumeric - id
    'Crater Azul', // String - title
    4.5, // Double - stars
    21, // Integer - amountReviews 
    ['../assets/craterAzul.jpg', '../assets/craterAzul2.jpg', '../assets/craterAzul3.jpg', '../assets/craterAzul4.jpg',], // Array - photos
    '../assets/precio60.png', // String - priceImg
    75, // Double - price
    // 1,
    [80, 75, 70, 65, 60],
    6, // Integer - time
    'El Cráter Azul se encuentra en medio de una densa selva tropical, rodeado de exuberante vegetación y una gran diversidad de vida silvestre. Su nombre proviene del intenso color azul turquesa de sus aguas, que contrasta de manera espectacular con el verdor de los alrededores. El cenote es una formación natural de agua dulce que se encuentra en el interior de un cráter de origen cárstico. Sus aguas cristalinas son profundas y ofrecen una increíble visibilidad, lo que lo convierte en un destino perfecto para los amantes del buceo, el snorkel y la natación.', // String - description
    [], // Array - horarios
    ['Río La Pasión', 'Cráter Azul', 'Imox',], // Array - highlights
    ['Transporte', 'Entrada'], // Array - included
    ['Propinas', 'Alimentos', 'Bebidas'], // Array - notIncluded
    departureFlores, // String - whatToExpect
    'Al visitar el Cráter Azul, se recomienda llevar traje de baño, protector solar, repelente de insectos, agua y una cámara para capturar la belleza del lugar. Es importante respetar y cuidar este entorno natural único, evitando dejar residuos y siguiendo las pautas de preservación del lugar.', // String - additionalInformation
    cancellation_policy, // String - cancellationPolicy
    [
        ...basicQuestions,
    ], // Array -> Object -> String - FAQ
    [], // Array - travelerPhotos
    [
        {
            stars: 4.5,
            title: 'Crater Azul fue único',
            name: 'Steve Ryanson',
            date: 'Abril de 2023',
            review: 'Gran tiempo nadando',
        },
    ], // Array -> Object - reviews
);

export const tours = [
    antiguaGuatemala,
    lagoAtitlan,
    chichicastenango,
    tikal,
    pacaya,
    cityTour,
    acatenango,
    hobbitenango,
    mistico,
    antiguaCityTour,
    antiguaAntiguaGuatemala,
    antiguaLagoAtitlan,
    antiguaChichicastenango,
    antiguaTikal,
    antiguaPacaya,
    antiguaAcatenango,
    antiguaHobbitenango,
    antiguaMistico,
    floresTikal,
    floresAmanecerTikal,
    floresYaxha,
    floresCeibal,
    floresAguateca,
    floresCraterAzul,
];