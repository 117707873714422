import { PDFViewer, StyleSheet } from '@react-pdf/renderer'
import { ReservationDocument } from './Files/ReservationDocument'
import '../styles/PDF.css'

export const PDFComponent = ({ reservationData, reservationCode }) => {
  const viewerStyle = StyleSheet.create({
    width: '58vw',
    height: '30vh',
  });

  return (
    <>
        <div className="pdf-container">
            <div className="pdf-viewer">
                <PDFViewer style={ viewerStyle }>
                    <ReservationDocument reservationData={ reservationData } reservationCode={ reservationCode } />
                </PDFViewer>
            </div>
        </div>
    </>
  )
}
