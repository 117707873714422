// import { CarouselComponent } from "./CarouselComponent"
import { useNavigate } from "react-router-dom"
import { FooterComponent } from "./FooterComponent"
import { ContactComponent } from "./ContactComponent";

export const HomePage = () => {
  const navigate = useNavigate();

  const handleClick = (e, link) => {
    e.preventDefault();
    
    navigate( link );
  };
  return (
    <>
      {/* DESKTOP VERSION */}
      <div className="top-welcome">
        <h2 className="custom-text top-welcome-upper left-align">Tours en</h2>
        <img src="../assets/GUATEMALA.png" className="img-fluid" alt="Guatemala" id="background-img" />
        <h2 className="custom-text right-align">Los mas visitados...</h2>
      </div>
      <div className="landing-grid">
        <div className="landing-grid-item">
          <a href="./tours/guate-antigua" onClick={ (event) => handleClick(event, './tours/guate-antigua') }> {/*  */}
            <img src="../assets/antigua.jpg" alt="Antigua Guatemala" className="tours-from-img" />
          </a>
        </div>
        <div className="landing-grid-item">
          <a href="./tours/guate-lago" onClick={ (event) => handleClick(event, './tours/guate-lago') }> {/*  */}
            <img src="../assets/atitlan-lago.jpg" alt="Atitlan Lake" className="tours-from-img" />
          </a>
        </div>
        <div className="landing-grid-item">
          <a href="./tours/guate-chichi" onClick={ (event) => handleClick(event, './tours/guate-chichi') }> {/*  */}
            <img src="../assets/chichi.jpg" alt="Chichicastenango" className="tours-from-img" />
          </a>
        </div>
        <div className="landing-grid-item">
          <a href="./tours/guate-tikal" onClick={ (event) => handleClick(event, './tours/guate-tikal') }> {/*  */}
            <img src="../assets/tikal-ruins.jpg" alt="Tikal" className="tours-from-img" />
          </a>
        </div>
        <div className="landing-grid-item">
          <a href="./tours/guate-pacaya" onClick={ (event) => handleClick(event, './tours/guate-pacaya') }> {/*  */}
            <img src="../assets/Pacaya1.jpg" alt="Pacaya" className="tours-from-img" />
          </a>
        </div>
        <div className="landing-grid-item">
          <a href="./tours/guate-acatenango" onClick={ (event) => handleClick(event, './tours/guate-acatenango') }> {/*  */}
            <img src="../assets/volcan-acatenango.jpg" alt="Acatenango" className="tours-from-img" />
          </a>
        </div>
        <div className="landing-grid-item">
          <a href="./tours/guate-hobbitenango" onClick={ (event) => handleClick(event, './tours/guate-hobbitenango') }> {/*  */}
            <img src="../assets/hobbitenango1.jpg" alt="Hobbitenango" className="tours-from-img" />
          </a>
        </div>
        <div className="landing-grid-item">
          <a href="./tours/guate-semuc" onClick={ (event) => handleClick(event, './tours/guate-semuc') }> {/*  */}
            <img src="../assets/semuc.jpg" alt="Semuc Champey" className="tours-from-img" />
          </a>
        </div>
        <div className="landing-grid-item">
          <a href="./tours/guate-city" onClick={ (event) => handleClick(event, './tours/guate-city') }> {/*  */}
            <img src="../assets/guatemala-city.jpg" alt="Guatemala City" className="tours-from-img" />
          </a>
        </div>
        <div className="landing-grid-item">
          <a href="./tours/guate-mistico" onClick={ (event) => handleClick(event, './tours/guate-mistico') }> {/*  */}
            <img src="../assets/mistico.jpg" alt="Tour Mistico" className="tours-from-img" />
          </a>
        </div>
      </div>
      {/* <div className="additional-access">
        <Link to="../contact"><button className="btn btn-outline-info">Contactanos</button></Link>
        <Link to="../about"><button className="btn btn-outline-info">Sobre Nosotros</button></Link>
      </div> */}
        <ContactComponent />
      <FooterComponent />
    </>
  )
}
