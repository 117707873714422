import { Document, Image, Link, Page, Text, View } from "@react-pdf/renderer"
import { styles } from './ReservationDocumentStyles'


export const ReservationDocument = ({ reservationData, reservationCode }) => {
//   const includesArr = ['item 1', 'item 2', 'item 3', 'item 4', 'item 5',];
//   const notIncludesArr = ['item 6', 'item 7', 'item 8', 'item 9', 'item 10',];
  const { name: nombre, date: fechaDeReserva, tour: nombreTour, schedule, passengers: cantidadPax, included: includesArr, notIncluded: notIncludesArr, price: precio } = reservationData;
  const hoy = new Date();
  const handleLoadSuccess = async pdf => {
      const page = await pdf.getPage(1);
      console.log(pdf);
      console.log(page.width);
  };
  console.log(reservationData, "Llegó");
  return (
    <Document title={`reserva-${nombre}-${hoy.getDate()}`} author="TurismoGuate.com" subject="Reservation" keywords="Reservation, Guatemala, Turismo" creator="TurismoGuate.com" language="es" pageLayout="singlePage" pageMode="fullScreen" onLoadSuccess={ handleLoadSuccess }>
        <Page size="A4" style={styles.page}>
            {/* TITULO */}
            <View style={styles.titleSection}>
                <Text style={styles.title}>
                    Reservation
                </Text>
                <Image src={`../assets/icono.png`} style={ styles.icono } />
            </View>
            {/* GREET */}
            <View style={styles.greetSection}>
                <Text>
                    Reciba un saludo cordial {`${nombre}\n\n`}
                    Es un gusto saludarle de parte de TourismoGuate.com, a continuación le adjunto la confirmación de la reserva realizada.{`\n\n`}
                </Text>
            </View>
            {/* TOUR INFORMATION */}
            <View style={styles.infoSection}>
                <View style={styles.infoSectionDetail}>
                    <View style={styles.fieldsSubject}>
                        <Text style={ styles.subject }>Fecha: </Text>
                    </View>
                    <View style={styles.fields}>
                        <Text>{ `${fechaDeReserva}` }</Text>
                    </View>
                </View>
                <View style={styles.infoSectionDetail}>
                    <View style={styles.fieldsSubject}>
                        <Text style={ styles.subject }>Servicio: </Text>
                    </View>
                    <View style={styles.fields}>
                        <Text>{ `${nombreTour}` }</Text>
                    </View>
                </View>
                <View style={styles.infoSectionDetail}>
                    <View style={styles.fieldsSubject}>
                        <Text style={ styles.subject }>Pasajeros: </Text>
                    </View>
                    <View style={styles.fields}>
                        <Text>{ `${cantidadPax}` }</Text>
                    </View>
                </View>
                <View style={styles.infoSectionDetail}>
                    <View style={styles.fieldsSubject}>
                        <Text style={ styles.subject }>Horario: </Text>
                    </View>
                    <View style={styles.fields}>
                        <Text>{ `${schedule}` }</Text>
                    </View>
                </View>
                <View style={styles.infoSectionColumn}>
                    <View style={styles.fieldsSubject}>
                        <Text style={ styles.subject }>Incluye: </Text>
                    </View>
                    <View style={styles.itemsColumn}>
                        {
                            includesArr.map( (item, index) => {
                                return (
                                    <>
                                        <View style={styles.itemColumn}>
                                            <Text>{ `{${item}}` }</Text>
                                        </View>
                                    </>
                                )
                            })
                        }
                    </View>
                </View>
                <View style={styles.infoSectionColumn}>
                    <View style={styles.fieldsSubject}>
                        <Text style={ styles.subject }>No Inluye: </Text>
                    </View>
                    <View style={styles.itemsColumn}>
                        {
                            notIncludesArr.map( (item, index) => {
                                return (
                                    <>
                                        <View style={styles.itemColumn}>
                                            <Text>{ `{${item}}` }</Text>
                                        </View>
                                    </>
                                )
                            })
                        }
                    </View>
                </View>
            </View>
            {/* PRECIO FINAL */}
            <View style={styles.totalPrice}>
                <Text>Precio total: { `${precio} `}</Text>
            </View>
            <View style={styles.divisorLine}></View>
            {/* ADVERTENCIA DE PAGO */}
            <View>
                <Text style={styles.paymentWarning}>*Para garantizar su reserva el 25% del pago será requerido.</Text>
            </View>
            <View style={styles.footer}>
                <View style={styles.footerText}>
                    <Link style={styles.link} src="https://turismoguate.com">TurismoGuate.com</Link>
                    <Link style={styles.link} src="https://wa.me/50257586000">+502 5850 7070</Link>
                    <Link style={styles.link} src="mailto:info@turismoguate.com?Subject=Mi%20Reserva">info@turismoguate.com</Link>
                </View>
            </View>
        </Page>
    </Document>
  )
}
